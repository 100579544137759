import React from 'react';
import {NavLink} from 'react-router-dom';
function Header(props){
    return(
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
                <span className="navbar-brand">Facturacion electronica </span>
                <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                        <NavLink to="/empresa" className="nav-link" activeClassName="active">
                            Datos de la empresa
                        </NavLink>
                    </li>
                    {props.u.Empresa === 1 ?
                    <li className="nav-item">
                        <NavLink to="/empresas" className="nav-link" activeClassName="active">
                            Empresas
                        </NavLink>
                    </li>
                    : null
                    }
                    <li className="nav-item">
                        <NavLink to="/usuarios" className="nav-link" activeClassName="active">
                            Usuarios
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/productos" className="nav-link" activeClassName="active">
                            Productos y servicios
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/clientes" className="nav-link" activeClassName="active">
                            Clientes
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/cfdi" className="nav-link" activeClassName="active">
                            CFDi's
                        </NavLink>
                    </li>
                </ul>
                <span style={{color:'white'}} size="sm">{props.u.Nombre}</span>
            </div>
        </nav>
    );
}

export default Header;