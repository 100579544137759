import React from 'react';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import EmpresasForm from './EmpresasForm';

class Empresas extends React.Component {
    empNueva = { Id: 0, Nombre: '', NombreCorto: '', Certificado : '', PwdKey : '' };

    constructor(props) {
        super(props);
        this.state = {
            editando: false,
            emp : this.props.u.Empresa,
            empActual: this.prodNuevo,
            empresas: []
        };
    }
    componentDidMount = () => {
        this.cargarEmpresas();
    }
    cargarEmpresas = async () => {
        const url = "https://www.facturar.isaerpweb.com/home/listaEmpresas";
        const res = await fetch(url);
        const empresas = await res.json();
        this.setState({ empresas });
    }
    cargarEmpresa = async (cod) => {
        const url = `https://www.facturar.isaerpweb.com/home/wscargaEmpresaAdmin/${cod}`;
        const res = await fetch(url);
        const empActual = await res.json();
        if(empActual.Id === 0){
            empActual.Id = cod;
        }
        this.setState({ editando: true, empActual });
    }
    cargarEmpresaNombre = async (cod) => {
        const url = `https://www.facturar.isaerpweb.com/home/wscargaEmpresaAdminNombre/${cod}`;
        const res = await fetch(url);
        const empActual = await res.json();
        console.log(empActual);
        if(empActual.Id === 0){
            empActual.NombreCorto = cod;
        }
        this.setState({ editando: true, empActual });
    }
    onClickNuevo = () =>{
        Swal.fire({
            title: 'Nombre corto',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (cod) => {
              this.cargarEmpresaNombre(cod);
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
    }
    editar = (cod) => {
        this.cargarEmpresa(cod);
    }
    eliminar = (cod) => {
    }
    cancelar = () => {
        this.setState({ editando: false });
    }
    guardar = async(empresa) => {
        const url = "https://www.facturar.isaerpweb.com/home/wsguardarEmpresaAdmin";
        const req = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(empresa),
          })
        const res = await req.text();
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false},()=> this.cargarEmpresas());
        Swal.fire('Atencion','Datos guardados.','success');
    }
    render() {
        if(this.props.u.Empresa > 1)
            return null;
        return (
            <div className="container">
                <h2 className="text-center">Empresas
                {"   "}
                {this.state.editando ? null: <Button variant="success" onClick={this.onClickNuevo}>+</Button>}
                </h2>
                {this.state.editando ?
                    <EmpresasForm empresa={this.state.empActual} guardar={this.guardar} cancelar={this.cancelar} />
                    :
                    <ListaEmpresas empresas={this.state.empresas} editar={this.editar} eliminar={this.eliminar} />
                }
            </div>
        )
    }
}

function ListaEmpresas({ empresas, editar, eliminar }) {
    return (
        <ul>
            {empresas.map((item, index) =>
                <EmpresaLista key={index} empresa={item} editar={editar} eliminar={eliminar} />
            )}
        </ul>
    );
}
function EmpresaLista({ empresa, editar, eliminar }) {
    return (
        <li className="list-group-item d-flex justify-content-between align-items-center" >
            <p>
                Clave:<span className="font-weight-bold">{empresa.NombreCorto}</span>
                {' '}
                <span>{empresa.Nombre}</span>
            </p>
            <div>
                <button className="btn btn-success" onClick={() => editar(empresa.Id)}>Editar</button>
                {' '}
                <button className="btn btn-primary" onClick={() => eliminar(empresa.Id)}>Eliminar</button>
            </div>
        </li>
    )
}
export default Empresas;