import React, { useState } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Buscador from './Buscador';


function ProductosForm(props) {
    const [producto, guardarProducto] = useState(props.producto);
    const popCodSat = (
        <Popover id="popover-basic" title="Buscar concepto">
            <Buscador onSelect={codigoSat} url="https://www.facturar.isaerpweb.com/factura/listaCodigosSat/" />
        </Popover>
    );
    const popUniSat = (
        <Popover id="popover-basic" title="Buscar unidad">
            <Buscador onSelect={unidadSat} url="https://www.facturar.isaerpweb.com/factura/listaUnidadesSat/" />
        </Popover>
    );

    function onChange(e) {
        const estado = {
            ...producto,
            [e.target.name]: e.target.value
        };
        guardarProducto(estado);
    }
    function onChangeCh(e) {
        const estado = {
            ...producto,
            [e.target.name]: e.target.checked
        };
        guardarProducto(estado);
    }
    function codigoSat(cod) {
        const estado = {
            ...producto,
            CodigoSat: cod
        };
        guardarProducto(estado);
    }
    function unidadSat(cod) {
        const estado = {
            ...producto,
            UnidadSat: cod
        };
        guardarProducto(estado);
    }
    function onSubmit(e) {
        e.preventDefault();
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                props.guardar(producto);
            }
          })        
        //MySwal.fire(<p>Shorthand works too</p>);
    }
    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Codigo</label>
                        <Form.Control size="sm" type="text" name="Codigo" value={producto.Codigo} onChange={onChange} />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Nombre</label>
                        <Form.Control size="sm" type="text" name="Nombre" value={producto.Nombre} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <label>Codigo SAT</label>
                    <InputGroup size="sm" className="mb-3">
                        <FormControl name="CodigoSat" value={producto.CodigoSat} className="form-control" onChange={onChange} 
                        />
                        <InputGroup.Append>
                            <OverlayTrigger trigger="click" placement="right" overlay={popCodSat} rootClose={true}>
                                <Button variant="success">Examinar</Button>
                            </OverlayTrigger>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                <div className="col">
                <div className="form-group" size="sm">
                        <label>&nbsp;</label>
                        <Form.Control size="sm" type="text" value={producto.NombreCodigo}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <label>Unidad SAT</label>
                    <InputGroup size="sm" className="mb-3">
                        <FormControl  name="UnidadSat" value={producto.UnidadSat} className="form-control" onChange={onChange} />
                        <InputGroup.Append>
                            <OverlayTrigger trigger="click" placement="right" overlay={popUniSat} rootClose={true}>
                                <Button variant="success">Examinar</Button>
                            </OverlayTrigger>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>&nbsp;</label>
                        <Form.Control size="sm" type="text" value={producto.NombreUnidad}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Unidad</label>
                        <Form.Control size="sm" type="text" name="Unidad" value={producto.Unidad} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Precio</label>
                        <Form.Control size="sm" type="text" name="Precio" value={producto.Precio} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>% IVA</label>
                        <Form.Control size="sm" type="text" name="TasaIva" value={producto.TasaIva} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>% IEPS</label>
                        <Form.Control size="sm" type="text" name="TasaIeps" value={producto.TasaIeps} onChange={onChange} />
                    </div>
                </div>
                <div className="col">
                    <br/>
                    <Form.Group controlId="formBasicChecbox">
                        <Form.Check type="checkbox" label="Impuesto incluido" checked={producto.IvaIncluido} name="IvaIncluido" onChange={onChangeCh} />
                        <Form.Check type="checkbox" label="Gravado" checked={producto.Gravado} name="Gravado" onChange={onChangeCh} />
                        <Form.Check type="checkbox" label="Pedir descripcion" checked={producto.PedirDescrip} name="PedirDescrip" onChange={onChangeCh} />
                    </Form.Group>
                </div>
            </div>
            <input type="submit" className="btn btn-sm btn-outline-success" value="Guardar" />
            {' '}
            <button className="btn btn-sm btn-outline-danger" onClick={props.cancelar}>Cancelar</button>
        </form>
    )
}
export default ProductosForm;