import React from 'react';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProductosForm from './ProductosForm';

class Productos extends React.Component {
    prodNuevo = { Empresa: 2, Codigo: '', Nombre: '', Unidad: '', CodigoSat: '', UnidadSat: '', Precio: 0, TasaIva: 16, TasaIeps: 0, Gravado: true, PedirDescrip: false, NombreUnidad: '', NombreCodigo: '', IvaIncluido : false };

    constructor(props) {
        super(props);
        this.state = {
            editando: false,
            emp : this.props.u.Empresa,
            prodActual: this.prodNuevo,
            productos: []
        };
    }
    componentDidMount = () => {
        this.cargarArticulos();
    }
    cargarArticulos = async () => {
        const url = "https://www.facturar.isaerpweb.com/articulo/wslista/?emp=" + this.state.emp;
        const res = await fetch(url);
        const productos = await res.json();
        this.setState({ productos });
    }
    cargarArticulo = async (cod) => {
        const url = `https://www.facturar.isaerpweb.com/articulo/wscargar/${cod}?emp=${this.state.emp}`;
        const res = await fetch(url);
        const prodActual = await res.json();
        if(prodActual.Codigo === ""){
            prodActual.Codigo = cod;
            prodActual.Empresa = this.state.emp;
        }
        this.setState({ editando: true, prodActual });
    }
    onClickNuevo = () =>{
        Swal.fire({
            title: 'Codigo',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (cod) => {
              this.cargarArticulo(cod);
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
    }
    editar = (cod) => {
        this.cargarArticulo(cod);
    }
    eliminar = (art) => {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar producto " + art.Dato1 + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.borrar(art.Id)
            }
          })        
    }
    borrar = async(cod) => {
        const url = `https://www.facturar.isaerpweb.com/articulo/wsborrar/${cod}?emp=${this.state.emp}`;
        const req = await fetch(url);
        const res = await req.text();
        if(res === "ok")
           this.cargarArticulos();
        else
            Swal.fire('Atencion',res,'error');           
    }
    cancelar = () => {
        this.setState({ editando: false });
    }
    guardar = async(articulo) => {
        const url = "https://www.facturar.isaerpweb.com/articulo/wsguardar";
        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(articulo), // data can be `string` or {object}!
          })
        const res = await req.text();
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false},()=> this.cargarArticulos());
        Swal.fire('Atencion','Datos guardados.','success');
    }
    render() {
        return (
            <div className="container">
                <h2 className="text-center">Productos
                {"   "}
                {this.state.editando ? null: <Button variant="success" onClick={this.onClickNuevo}>+</Button>}
                </h2>
                {this.state.editando ?
                    <ProductosForm producto={this.state.prodActual} guardar={this.guardar} cancelar={this.cancelar} />
                    :
                    <ListaArticulos productos={this.state.productos} editar={this.editar} eliminar={this.eliminar} />
                }
            </div>
        )
    }
}

function ListaArticulos({ productos, editar, eliminar }) {
    return (
        <ul>
            {productos.map((item, index) =>
                <ProductoLista key={index} producto={item} editar={editar} eliminar={eliminar} />
            )}
        </ul>
    );
}
function ProductoLista({ producto, editar, eliminar }) {
    return (
        <li className="list-group-item d-flex justify-content-between align-items-center" >
            <p>
                Codigo:<span className="font-weight-bold">{producto.Id}</span>
                {' '}
                <span>{producto.Dato1}</span>
            </p>
            <div>
                <button className="btn btn-sm btn-outline-success" onClick={() => editar(producto.Id)}>Editar</button>
                {' '}
                <button className="btn btn-sm btn-outline-danger" onClick={() => eliminar(producto)}>Eliminar</button>
            </div>
        </li>
    )
}
export default Productos;