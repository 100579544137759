import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Usuarios from './Usuarios';
function EmpresasForm(props) {
    const [empresa, guardarEmpresa] = useState(props.empresa);
    function onChange(e) {
        const estado = {
            ...empresa,
            [e.target.name]: e.target.value
        };
        guardarEmpresa(estado);
    }
    function onSubmit(e) {
        e.preventDefault();
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                props.guardar(empresa);
            }
          })        
    }
    return (
        <div>
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Nombre Corto</label>
                        <Form.Control size="sm" type="text" name="NombreCorto" value={empresa.NombreCorto}/>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Nombre</label>
                        <Form.Control size="sm" type="text" name="Nombre" value={empresa.Nombre} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Certificado</label>
                        <Form.Control size="sm" type="text" name="Certificado" value={empresa.Certificado} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Contraseña</label>
                        <Form.Control size="sm" type="text" name="PwdKey" value={empresa.PwdKey} onChange={onChange} />
                    </div>
                </div>
            </div>
            <input type="submit" className="btn btn-success" value="Guardar" />
            {' '}
            <button className="btn btn-primary" onClick={props.cancelar}>Cancelar</button>
        </form>
        {empresa.Id > 0 ? <Usuarios emp={empresa.Id} /> : null}
        </div>
    )
}
export default EmpresasForm;