import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function UsuarioForm(props) {
    const [usuario, guardarUsuario] = useState(props.usuario);

    function onChange(e) {
        const estado = {
            ...usuario,
            [e.target.name]: e.target.value
        };
        guardarUsuario(estado);
    }
    function onSubmit(e) {
        e.preventDefault();
        console.log(usuario);
        if(usuario.Pwd === '' || (usuario.Pwd !== usuario.PwdB)){
            Swal.fire('Atencion',"La contraseña es incorrecta",'error');
            return;
        }
        const MySwal = withReactContent(Swal)


        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                props.guardar(usuario);
            }
          })        
        //MySwal.fire(<p>Shorthand works too</p>);
    }
    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Nombre</label>
                        <Form.Control size="sm" type="text" name="Nombre" value={usuario.Nombre} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Email</label>
                        <Form.Control size="sm" type="text" name="Email" value={usuario.Email} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Contraseña</label>
                        <Form.Control size="sm" type="password" name="Pwd" value={usuario.Pwd} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Confirmar</label>
                        <Form.Control size="sm" type="password" name="PwdB" value={usuario.PwdB} onChange={onChange} />
                    </div>
                </div>
            </div>
            <input type="submit" className="btn btn-sm btn-outline-success" value="Guardar" />
            {' '}
            <button className="btn btn-sm btn-outline-danger" onClick={props.cancelar}>Cancelar</button>
        </form>
    )
}
export default UsuarioForm;