import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Captura from './Captura';
import { async } from 'q';
import { Container } from 'react-bootstrap';

class CFDi extends React.Component{
    constructor(props){
        super(props);
        //let fec = new Date().toISOString().substring(0,10);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            formaPagoPA:'',
            clientePago:0,
            conceptoNC:'',
            tipoRelNC:'',
            formaNC:'',
            mostrarCancelar:false,
            folioCancelar:'',
            cveCanc:'',
            numCanc:0,
            numRel:0,
            motivo:'',
            todas:false,
            importeNC:0,
            mostrarNC:false,
            editando:false,
            editandoPago:false,
            emp : this.props.u.Empresa,
            tipo:'',
            clienteFiltro:0,
            fechaIni:fecIni,
            fechaFin:fecFin,
            tipos:[],
            facturas:[],
            metodos:[],
            formas:[],
            usos:[],
            relaciones:[],
            clientes:[],
            codigos:[],
            motivos:[],
            facturaActual:{},
            foliosTimbrado:{Folios:0,Timbrados:0,Cancelados:0,Saldo:0}
        };        
    }
    componentDidMount = () =>{
        this.cargarTipos();
        const estado = localStorage.getItem("facturas");
        if(estado === null){
            this.cargarCatalogos();
            return;
        }
        this.setState(JSON.parse(estado),()=>{
            if(this.state.clientes === undefined || this.state.clientes.length === 0 || this.state.codigos === undefined || this.state.codigos.length === 0)
               this.cargarCatalogos();
        });
    }
    componentWillUnmount(){
        const estado = JSON.stringify(this.state);
        localStorage.setItem("facturas", estado);
    }
    cargarCatalogos = async () => {
        let res = await fetch(this.props.urlws + "/cfdi/listaMetodos");
        const metodos = await res.json();
        res = await fetch(this.props.urlws + "/factura/listaRelacion");
        const relaciones = await res.json();
        res = await fetch(this.props.urlws + "/cfdi/listaFormas");
        const formas = await res.json();
        res = await fetch(this.props.urlws + "/cfdi/listaUsos");
        const usos = await res.json();
        res = await fetch(this.props.urlws + "/cfdi/listaMotivosCancelacion");
        const motivos = await res.json();
        res = await fetch(this.props.urlws + "/cliente/wslista?emp=" + this.state.emp);
        const clientes = await res.json();
        res = await fetch(this.props.urlws + "/articulo/wslista?emp=" + this.state.emp);
        const codigos = await res.json();
        res = await fetch(this.props.urlws + "/home/wscargaEmpresaFolios/" + this.state.emp);
        const foliosTimbrado = await res.json();
        res = await fetch(this.props.urlws + "/factura/listaPeriodicidad");
        const periodicidad = await res.json();
        this.setState({ formas, metodos, usos, clientes, relaciones, codigos, motivos, foliosTimbrado, periodicidad });
    }
    cargarClientes = async () => {
        let res = await fetch(this.props.urlws + "/cliente/wslista?emp=" + this.state.emp);
        const clientes = await res.json();
        res = await fetch(this.props.urlws + "/articulo/wslista?emp=" + this.state.emp);
        const codigos = await res.json();
        this.setState({  clientes, codigos});
    }
    cargarTipos = async() =>{
        const url = this.props.urlws + "/factura/wslistaTipos/" + this.state.emp;
        const res = await fetch(url);
        const tipos = await res.json();
        this.setState({tipos});
    }
    cargarFacturas = async() =>{
        //2019-08-10
        const tipo = this.state.tipo;
        if(tipo === ""){
            Swal.fire('Atencion','Falta indicar el tipo de documento','success');
            return;
        }
        let todas = this.state.todas?"S":"N";
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fecIni = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let fecFin = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        let cte = this.state.clienteFiltro;
        let url = `${this.props.urlws}/factura/wslista?cte=${cte}&emp=${this.state.emp}&clave=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}&todas=${todas}`;
        let  res = await fetch(url);
        const facturas = await res.json();
        res = await fetch(this.props.urlws + "/home/wscargaEmpresaFolios/" + this.state.emp);
        const foliosTimbrado = await res.json();
        this.setState({facturas, foliosTimbrado})
    }
    imprimirFacturas = () =>{
        const tipo = this.state.tipo;
        if(tipo === ""){
            Swal.fire('Atencion','Falta indicar el tipo de documento','success');
            return;
        }
        let todas = this.state.todas?"S":"N";
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fecIni = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let fecFin = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        let cte = this.state.clienteFiltro;
        const url = `${this.props.urlws}/factura/wslistado?cte=${cte}&emp=${this.state.emp}&clave=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}&todas=${todas}`;
        window.open(url, '', '');
    }
    descargarFacturas = () =>{
        const tipo = this.state.tipo;
        if(tipo === ""){
            Swal.fire('Atencion','Falta indicar el tipo de documento','success');
            return;
        }
        let todas = this.state.todas?"S":"N";
        let f1 = this.state.fechaIni;
        let f2 = this.state.fechaFin;
        let fecIni = f1.substring(0,4) + f1.substring(5,7) + f1.substring(8);
        let fecFin = f2.substring(0,4) + f2.substring(5,7) + f2.substring(8);
        let cte = this.state.clienteFiltro;
        const url = `${this.props.urlws}/factura/descargar?cte=${cte}&emp=${this.state.emp}&clave=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}&todas=${todas}`;
        window.open(url, '', '');
    }

    nueva = () =>{
        this.setState({editando:true});
    }
    nuevoPago = () =>{
        this.setState({editandoPago:true});
    }
    onChange = (e) =>{
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.value
        });
    }
    onChangeCh = (e) => {
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.checked
        });
    }
    guardarNC = () =>{
        const impNC = this.state.importeNC;
        if(impNC > this.state.facturaActual.Saldo){
            Swal.fire('Atencion','El importe es mayor al saldo de la factura','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar la Nota de Credito ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarNCB();
            }
        });

    }
    guardarNCB = async() => { //async() => {
        const url = this.props.urlws + "/factura/wscrearNC";
        const data = {
            Empresa :this.state.emp,
            Id: this.state.facturaActual.UUID,
            Forma: this.state.formaNC,
            Relacion: this.state.tipoRelNC,
            Concepto: this.state.conceptoNC,
            Importe: this.state.importeNC
        };

        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        this.setState({editando: false,mostrarNC:false},()=> this.cargarFacturas());
        Swal.fire('Atencion','Datos guardados.','success');
        let params = '/' + this.state.emp + '-' + res;
        const urlImp = this.props.urlws + '/factura/wsimprimir' + params;
        window.open(urlImp, '', '');
        return "ok";
    }
    guardar = async(data) => { //async() => {
        const url = this.props.urlws + "/factura/wsguardar";
        //const url = "http://localhost:29674/factura/wsguardar";
        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        this.setState({editando: false},()=> this.cargarFacturas());
        Swal.fire('Atencion','Datos guardados.','success');
        let params = '/' + this.state.emp + '-' + res;
        const urlImp = this.props.urlws  + '/factura/wsimprimir' + params;
        window.open(urlImp, '', '');
        return "ok";
    }
    cancelarCfdi = (cve,num) =>{
        this.setState({numRel:0, motivo:'', cveCanc:cve, numCanc:num,folioCancelar: cve + "-" + num, mostrarCancelar:true});
    }
    cancelarCfdiB = (cve,num) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar este CFDI " + cve + " - " + num + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.cancelarCfdiC(cve,num);
            }
        });
    }
    cancelarCfdiC = async() =>{
        const url = this.props.urlws + "/factura/wscancelar?emp=" + this.state.emp + '&clave=' + this.state.cveCanc + '&numero=' + this.state.numCanc + '&motivo=' + this.state.motivo + '&cveRel=' + this.state.numCanc+ '&numRel=' + this.state.numRel;
        //const url = "http://localhost:29674/factura/wscancelar?emp=" + this.state.emp + '&clave=' + cve + '&numero=' + num;
        const req = await fetch(url, {
            method: 'POST'
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        Swal.fire('Atencion','Se cancelo el CFDI','success');
        this.setState({mostrarCancelar:false});
        this.cargarFacturas();
        return "ok";
    }
    enviarEmailB = async(cve,num,email) => { //async() => {
        const url = this.props.urlws + "/factura/wsenviarEmailFactura/" + this.state.emp + '-' + cve + '-' + num + '-' + email;
        //const url = "http://localhost:29674/factura/wsguardar";
        const req = await fetch(url, {
            method: 'POST' // or 'PUT'
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        Swal.fire('Atencion','Se envio el email.','success');
        return "ok";
    }

    cancelar = () => {
        this.setState({editando: false, editandoPago:false});
    }
    cerrarPA = () => {
        this.setState({editandoPago: false});
    }
    cerrarNC = () => {
        this.setState({mostrarNC: false});
    }
    cerrarCancelar = () => {
        this.setState({mostrarCancelar: false});
    }
    crearNC = (fact) =>{
        console.log(fact);
        this.setState({mostrarNC: true, facturaActual:fact, importeNC : 0});
    }
    enviarEmail = async(cve,num,email) =>{
        const {value: emailCap} = await Swal.fire({
            title: 'Enviar archivos por email',
            input: 'email',
            inputValue: email,
            inputPlaceholder: 'Direccion de email'
          })
          
          if (emailCap) {
            this.enviarEmailB(cve,num,emailCap);
          }
    }


    imprimir = (cve,num) =>{
        let params = '/' + this.state.emp + '-' + cve + '-' + num;
        let url = this.props.urlws + '/factura/wsimprimir' + params;
        window.open(url, '', '');
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(
            <div className="container">
                <div style={estilo1}>
                    <Captura guardar={this.guardar} cancelar={this.cancelar} metodos={this.state.metodos} formas={this.state.formas} usos={this.state.usos} clientes={this.state.clientes} codigos={this.state.codigos} emp={this.state.emp} urlws={this.props.urlws} cargarClientes={this.cargarClientes} relaciones={this.state.relaciones} periodicidad={this.state.periodicidad}/>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col">
                            <b>CFDi</b>
                            <span>&nbsp;Pagados : <b>{this.state.foliosTimbrado.Folios}</b></span>
                            <span>&nbsp;Usados : <b>{this.state.foliosTimbrado.Timbrados + this.state.foliosTimbrado.Cancelados}</b></span>
                            <span>&nbsp;Saldo : <b>{this.state.foliosTimbrado.Saldo}</b></span>
                        </div>
                        <Form.Group as={Col}>
                            <Form.Control size="sm" as="select" name="clienteFiltro" value={this.state.clienteFiltro} onChange={this.onChange}>
                            <option key="0" value="0">Todos</option>
                            {this.state.clientes.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Button variant='outline-success' size="sm" onClick={this.cargarClientes}><img src="refresh.png" alt="actualizar" /></Button>
                            {'  '}
                            <Button variant='secondary' size="sm" onClick={this.imprimirFacturas}>Listado</Button>
                            {'  '}
                            <Button variant='secondary' size="sm" onClick={this.descargarFacturas}>Descargar</Button>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <Form.Group as={Col} md="2">
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control size="sm" as="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                                <option key="0" value="">Seleccionar</option>
                                {this.state.tipos.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Del dia</Form.Label>
                            <Form.Control size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>al dia</Form.Label>
                            <Form.Control size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col} md="1">
                            <Form.Label></Form.Label><br />
                            <Form.Check type="checkbox" label="Todas" checked={this.state.todas} name="todas" onChange={this.onChangeCh} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label></Form.Label><br />
                            <Button variant='success' onClick={this.cargarFacturas}>Cargar</Button>
                            {'  '}
                            <Button variant='success' onClick={this.nueva}>+ factura</Button>
                            {'  '}
                            <Button variant='success' onClick={this.nuevoPago}>+ pago</Button>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <ListaFacturas facturas={this.state.facturas} imprimir={this.imprimir} enviarEmail={this.enviarEmail} cancelar={this.cancelarCfdi} nc={this.crearNC}/>
                    </div>
                </div>
                <div className="row">
                <Modal show={this.state.mostrarNC} onHide={this.cerrarNC}>
                    <Modal.Header closeButton>
                        <Modal.Title>Nota de credito</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Tipo de relacion</Form.Label>
                                <Form.Control size="sm" as="select" name="tipoRelNC" value={this.state.tipoRelNC} onChange={this.onChange}>
                                    <option key="0" value="">Seleccionar</option>
                                    {this.state.relaciones.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Forma de pago</Form.Label>
                                <Form.Control size="sm" as="select" name="formaNC" value={this.state.formaNC} onChange={this.onChange}>
                                    <option key="0" value="">Seleccionar</option>
                                    {this.state.formas.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Factura</Form.Label>
                                <input size="sm" type="text" className="form-control" value={this.state.facturaActual.Numero} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Pagos</Form.Label>
                                <input size="sm" type="text" className="form-control" value={this.state.facturaActual.Pagos} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>NC ant</Form.Label>
                                <input size="sm" type="text" className="form-control" value={this.state.facturaActual.Nc} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Saldo</Form.Label>
                                <input size="sm" type="text" className="form-control" value={this.state.facturaActual.Saldo} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Importe</Form.Label>
                                <input size="sm" type="text" className="form-control" name="importeNC" value={this.state.importeNC} onChange={this.onChange} />
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Concepto</Form.Label>
                                <input size="sm" type="text" className="form-control" name="conceptoNC" value={this.state.conceptoNC} onChange={this.onChange} />
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.guardarNC}>Guardar</Button>
                        <Button variant="secondary" onClick={this.cerrarNC}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>                
                <div className="col-10">
                    <FormPago show={this.state.editandoPago} clientes={this.state.clientes} formas={this.state.formas} emp={this.state.emp} onHide={this.cerrarPA}/>
                </div>
                <Modal show={this.state.mostrarCancelar} onHide={this.cerrarCancelar}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancelar {this.state.folioCancelar}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Motivo</Form.Label>
                                <Form.Control size="sm" as="select" name="motivo" value={this.state.motivo} onChange={this.onChange}>
                                    <option key="0" value="">Seleccionar</option>
                                    {this.state.motivos.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        {
                        this.state.motivo === "01" &&
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Factura relacionada</Form.Label>
                                <input size="sm" type="text" className="form-control" name="numRel" value={this.state.numRel} onChange={this.onChange} style={{width:'100px'}} />
                            </Form.Group>
                        </div>
                        }
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" size="sm" onClick={this.cancelarCfdiC}> &nbsp;&nbsp;Si&nbsp;&nbsp; </Button>
                        <Button variant="danger" size="sm" onClick={this.cerrarCancelar}> &nbsp;&nbsp;No&nbsp;&nbsp; </Button>
                    </Modal.Footer>
                </Modal>                
                </div>
            </div>
        )
    }
}

function ListaFacturas({ facturas,imprimir,enviarEmail,cancelar,nc }) {
    return (
        <div style={{ height: 500, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{ width: 55 }}>Serie</th>
                        <th style={{ width: 75 }}>Numero</th>
                        <th style={{ width: 85 }}>Fecha</th>
                        <th style={{ width: 450 }}>Cliente</th>
                        <th style={{ width: 100, textAlign: 'right' }}>Total</th>
                        <th>Cancelada</th>
                        <th>Timbrada</th>
                    </tr>
                </thead>
                <tbody>
                    {facturas.map((fact, i) => (
                        <FacturaRow key={i} factura={fact} imprimir={imprimir} enviarEmail={enviarEmail} cancelar={cancelar} nc={nc}/>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
function FacturaRow({ factura,imprimir, enviarEmail,cancelar,nc }) {
    return (
        <tr>
            <td>{factura.Clave}</td>
            <td>{factura.Numero}</td>
            <td>{factura.Fecha}</td>
            <td>{factura.Nombre}</td>
            <td style={{width:100, textAlign:'right'}}>{factura.TotalStr}</td>
            <td className="text-center"><Form.Check type="checkbox" checked={factura.Cancelada}/></td>
            <td className="text-center"><Form.Check type="checkbox" checked={factura.Timbrada}/></td>
            <td><Button variant="outline-success" size="sm" onClick={() => imprimir(factura.Clave,factura.Numero)}>Imprimir</Button></td>
            <td><Button variant="outline-primary" size="sm" onClick={() => enviarEmail(factura.Clave,factura.Numero,factura.Email)}>Enviar</Button></td>
            <td><Button variant="outline-danger" disabled={factura.Cancelada} size="sm" onClick={() => cancelar(factura.Clave,factura.Numero)}>Cancelar</Button></td>
            {
            (factura.Clave === "FA" || factura.Clave === "FC")?
                <td><Button variant="outline-success" disabled={factura.Cancelada} size="sm" onClick={() => nc(factura)}>N.Credito</Button></td>
            :null
            }
        </tr>
    )
}

class FormPago extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        let fecIni = fec.yyyymmdd('-');
        this.state = {
            fecha:fecIni,
            cliente:0,
            importe:0,
            forma:'',
            facturasPagos:[]
        }
    }
    cargarFacturasPagos = async() =>{
        const url = `https://www.facturar.isaerpweb.com/factura/wsfacturasSaldo/${this.state.cliente}?emp=${this.props.emp}`;
        const res = await fetch(url);
        const facturasPagos = await res.json();
        this.setState({facturasPagos, importe:0});
    }
    onChange = (e) =>{
        const campo = e.target.name;
        this.setState({
            [campo]: e.target.value
        },()=>{
            if(campo === "cliente")
                this.cargarFacturasPagos();
        });
    }
    modificaPago = (i,v) =>{
        let x  = this.state.facturasPagos;
        x[i].Pago = v;
        let importe = 0;
        for (let i = 0; i < this.state.facturasPagos.length; i++) {
            if (this.state.facturasPagos[i].Pago > 0) 
                importe += Number(this.state.facturasPagos[i].Pago);
        }
        this.setState({facturasPagos : x, importe});
    }
    guardar = () =>{
        if(this.state.forma === ''){
            Swal.fire('Atencion','Falta indicar la forma de pago','success');
            return;
        }
        let det = [];
        for (let i = 0; i < this.state.facturasPagos.length; i++) {
            if (this.state.facturasPagos[i].Pago > 0) {
                det.push({
                    cve: this.state.facturasPagos[i].Clave,
                    num: this.state.facturasPagos[i].Numero,
                    imp: this.state.facturasPagos[i].Pago
                });
            }
        }
        let json = JSON.stringify(det);
        let data = {
            emp : this.props.emp,
            fechaPagoStr:this.state.fecha,
            forma:this.state.forma,
            cte:this.state.cliente,
            s:json
        };
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este pago ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB(data);
            }
        });
    }
    guardarB = async(data) => {
        const url = "https://www.facturar.isaerpweb.com/factura/wsguardarPago";
        //const url = "http://localhost:29674/factura/wsguardar";
        const req = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
          })
        const res = await req.text();
        if(res.substring(0,5) === "Error"){
            Swal.fire('Atencion',res,'error');
            return res;
        }
        this.props.onHide();
        Swal.fire('Atencion','Datos guardados.','success');
        let params = '/' + this.props.emp + '-' + res;
        const urlImp = 'https://www.facturar.isaerpweb.com/factura/wsimprimir' + params;
        window.open(urlImp, '', '');
        return "ok";
    }
    render(){
        return(
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Form.Group as={Col} md="3">
                            <Form.Label>Fecha</Form.Label>
                            <Form.Control size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Cliente</Form.Label>
                            <Form.Control size="sm" as="select" name="cliente" value={this.state.cliente} onChange={this.onChange}>
                                <option key="0" value="">Seleccionar</option>
                                {this.props.clientes.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Forma de pago</Form.Label>
                            <Form.Control size="sm" as="select" name="forma" value={this.state.forma} onChange={this.onChange}>
                                <option key="0" value="">Seleccionar</option>
                                {this.props.formas.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="row">
                        <div style={{ height: 300, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 55 }}>Serie</th>
                                        <th style={{ width: 75 }}>Numero</th>
                                        <th style={{ width: 90 }}>Importe</th>
                                        <th style={{ width: 90 }}>Saldo</th>
                                        <th style={{ width: 90 }}>Pago</th>
                                        <th>UUID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.facturasPagos.map((fact, i) => (
                                        <FacturaPago dato={fact} indice={i} modificaPago={this.modificaPago} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Group as={Col} md="2">
                        <Form.Label>Importe</Form.Label>
                        <Form.Control size="sm" type="text" value={this.state.importe} />
                    </Form.Group>
                    <Button variant="success" onClick={this.guardar}>Guardar</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>                
        );
    }
}
class FacturaPago extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pago:0
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.dato.Numero !== prevProps.dato.Numero)
          this.setState({pago:0});
    }
    onChange = (e) =>{
        this.setState({pago:e.target.value});
        this.props.modificaPago(this.props.indice,e.target.value);
    }
    render(){
        return(
            <tr key={this.props.indice}>
                <td style={{width:55}}>{this.props.dato.Clave}</td>
                <td style={{width:75}}>{this.props.dato.Numero}</td>
                <td style={{width:90, textAlign:'right'}}>{this.props.dato.Total}</td>
                <td style={{width:90, textAlign:'right'}}>{this.props.dato.Saldo}</td>
                <td style={{width:90, textAlign:'right'}}>
                    <input size="sm" type="text" className="form-control" value={this.state.pago} onChange={this.onChange}/>
                </td>
                <td>{this.props.dato.UUID}</td>
            </tr>
        )
    }
} 
export default CFDi;