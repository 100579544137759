import React from 'react';
import UsuarioForm from './UsuarioForm';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';

class Usuarios extends React.Component{
    usuNuevo = {Id:0, Empresa:2, Email:'', Pwd:'', Nombre:'', NombreEmpresa:'', PwdB:''};
    constructor(props){
        super(props);
        this.state = {
            editando: false,
            usuActual: this.usuNuevo,
            emp : this.props.u === undefined ? this.props.emp : this.props.u.Empresa,
            usuarios: []
        };
    }
    componentDidMount = () => {
        this.cargarUsuarios();
    }
    cargarUsuarios = async () => {
        const url = "https://www.facturar.isaerpweb.com/usuario/wslista/" + this.state.emp;
        const res = await fetch(url);
        const usuarios = await res.json();
        this.setState({ usuarios });
    }
    cargarUsuario = async (cod) => {
        const url = `https://www.facturar.isaerpweb.com/usuario/wscargar/${cod}`;
        const res = await fetch(url);
        const usuActual = await res.json();
        if(usuActual.Id === 0){
            usuActual.Id = cod;
            usuActual.Empresa = this.state.emp;
        }
        usuActual.PwdB = usuActual.Pwd;
        this.setState({ editando: true, usuActual });
    }
    onClickNuevo = () =>{
        this.cargarUsuario(0);
    }
    editar = (cod) => {
        this.cargarUsuario(cod);
    }
    eliminar = (cod) => {
    }
    cancelar = () => {
        this.setState({ editando: false });
    }
    guardar = async(usuario) => {
        const url = "https://www.facturar.isaerpweb.com/usuario/wsguardar";
        const req = await fetch(url, {
            method: 'POST', 
            body: JSON.stringify(usuario),
          })
        const res = await req.text();
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false},()=> this.cargarUsuarios());
        Swal.fire('Atencion','Datos guardados.','success');
    }
    render(){
        return(
            <div className="container">
                <h2 className="text-center">Usuarios
                {"   "}
                {this.state.editando ? null: <Button variant="success" onClick={this.onClickNuevo}>+</Button>}
                </h2>
                {this.state.editando ?
                    <UsuarioForm usuario={this.state.usuActual} guardar={this.guardar} cancelar={this.cancelar} />
                :
                    <ListaUsuarios usuarios={this.state.usuarios} editar={this.editar} eliminar={this.eliminar} />
                }
            </div>
        )
    }
}

function ListaUsuarios({ usuarios, editar, eliminar }) {
    return (
        <ul>
            {usuarios.map((item, index) =>
                <UsuarioLista key={index} usuario={item} editar={editar} eliminar={eliminar} />
            )}
        </ul>
    );
}
function UsuarioLista({ usuario, editar, eliminar }) {
    return (
        <li className="list-group-item d-flex justify-content-between align-items-center" >
            <p>
                Codigo:<span className="font-weight-bold">{usuario.Id}</span>
                {' '}
                <span>{usuario.Dato1}</span>
            </p>
            <div>
                <button className="btn btn-sm btn-outline-success" onClick={() => editar(usuario.Id)}>Editar</button>
                {' '}
                <button className="btn btn-sm btn-outline-danger" onClick={() => eliminar(usuario.Id)}>Eliminar</button>
            </div>
        </li>
    )
}

export default Usuarios;