import React from 'react';
import ClientesForm from './ClientesForm';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Button from 'react-bootstrap/Button';

class Clientes extends React.Component{
    cteNuevo = {"Empresa":2,"Clave":0,"Nombre":'',"Calle":'',"Orientacion":'',"NumeroExt":'',"NumeroInt":'',"Colonia":'',"Rfc":'',"Ciudad":'',"Municipio":'',"Estado":'',"Pais":'',"Telefono":'',"Celular":'',"CP":'',"Curp":'',"Email":'',"NombreComercial":'',"ConCredito":false,"Plazo":0,"Limite":0,"Cuenta":'',"CuentaPago":'',"Contacto":'',"Sector":0,"PtjeRetIsr":0,"PtjeRetIva":0,"FormaPago":'',"MetodoPago":'',"UsoCfdi":'',"Direccion":'',"Localidad":''};
    constructor(props){
        super(props);
        this.state = {
            editando: false,
            emp : this.props.u.Empresa,
            cteActual: this.cteNuevo,
            regimenes: [],
            clientes: [],
            metodos:[],
            formas:[],
            usos:[]
        };
    }
    componentDidMount = () => {
        this.cargarCatalogos();
        this.cargarClientes();
    }
    cargarCatalogos = async () => {
        let res = await fetch("https://www.facturar.isaerpweb.com/cfdi/listaMetodos");
        const metodos = await res.json();
        res = await fetch("https://www.facturar.isaerpweb.com/cfdi/listaFormas");
        const formas = await res.json();
        res = await fetch("https://www.facturar.isaerpweb.com/cfdi/listaUsos");
        const usos = await res.json();
        res = await fetch("https://facturar.isaerpweb.com/home/wslistaRegimenes");
        const regimenes = await res.json();
        this.setState({ formas, metodos, usos, regimenes });
    }
    cargarClientes = async () => {
        const url = "https://www.facturar.isaerpweb.com/cliente/wslista/?emp=" + this.state.emp;
        const res = await fetch(url);
        const clientes = await res.json();
        this.setState({ clientes });
    }
    cargarCliente = async (cod) => {
        const url = `https://www.facturar.isaerpweb.com/cliente/wscargar/${cod}?emp=${this.state.emp}`;
        const res = await fetch(url);
        const cteActual = await res.json();
        if(cteActual.Clave === 0){
            cteActual.Clave = cod;
            cteActual.Empresa = this.state.emp;
        }
        this.setState({ editando: true, cteActual });
    }
    onClickNuevo = () =>{
        this.cargarCliente(0);
    }
    editar = (cod) => {
        this.cargarCliente(cod);
    }
    eliminar = (cte) => {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar el cliente " + cte.Dato1 + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.borrar(cte.Id)
            }
          })        
    }
    borrar = async(cod) => {
        const url = `https://www.facturar.isaerpweb.com/cliente/wsborrar/${cod}?emp=${this.state.emp}`;
        const req = await fetch(url);
        const res = await req.text();
        if(res === "ok")
           this.cargarClientes();
        else
            Swal.fire('Atencion',res,'error');           
    }
    cancelar = () => {
        this.setState({ editando: false });
    }
    guardar = async(cliente) => {
        const url = "https://www.facturar.isaerpweb.com/cliente/wsguardar";
        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(cliente), // data can be `string` or {object}!
          })
        const res = await req.text();
        if(res.substring(0,2) !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.setState({editando: false},()=> this.cargarClientes());
        Swal.fire('Atencion','Datos guardados.','success');
    }

    render(){
        return(
            <div className="container">
                <h2 className="text-center">Clientes
                {"   "}
                {this.state.editando ? null: <Button variant="success" onClick={this.onClickNuevo}>+</Button>}
                </h2>
                {this.state.editando ?
                    <ClientesForm cliente={this.state.cteActual} metodos={this.state.metodos} formas={this.state.formas} usos={this.state.usos} regimenes={this.state.regimenes} guardar={this.guardar} cancelar={this.cancelar} />
                :
                    <ListaClientes clientes={this.state.clientes} editar={this.editar} eliminar={this.eliminar} />
                }
            </div>
        )
    }
}

function ListaClientes({ clientes, editar, eliminar }) {
    return (
        <ul>
            {clientes.map((item, index) =>
                <ClienteLista key={index} cliente={item} editar={editar} eliminar={eliminar} />
            )}
        </ul>
    );
}
function ClienteLista({ cliente, editar, eliminar }) {
    return (
        <li className="list-group-item d-flex justify-content-between align-items-center" >
            <p>
                Codigo:<span className="font-weight-bold">{cliente.Id}</span>
                {' '}
                <span>{cliente.Dato1}</span>
            </p>
            <div>
                <button className="btn btn-sm btn-outline-success" onClick={() => editar(cliente.Id)}>Editar</button>
                {' '}
                <button className="btn btn-sm btn-outline-danger" onClick={() => eliminar(cliente)}>Eliminar</button>
            </div>
        </li>
    )
}

export default Clientes;