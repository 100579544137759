import React from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Empresa from './componentes/Empresa';
import Productos from './componentes/Productos';
import Clientes from './componentes/Clientes';
import Usuarios from './componentes/Usuarios';
import Empresas from './componentes/Empresas';
import CFDi from './componentes/CFDi';
import Header from './Header';
import Login from './componentes/Login';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      urlws:'https://www.facturar.isaerpweb.com',
      urlwsX:'http://localhost:29674',
      lg:false,
      emp:0,
      u:{}
    }
  }
  componentDidMount = () => {
    localStorage.removeItem("facturas");
    localStorage.removeItem("captura");
    localStorage.removeItem("u");
  }
  iniciar = (usu) =>{
    this.setState({
      lg:true, emp : usu.Empresa, u : usu
    });
    const u = JSON.stringify(usu);
    localStorage.setItem("u", u);
  }
  render(){
    const estilo = {visibility : this.state.lg?'visible':'hidden'};
    console.log(this.state.u.Empresa);
    return (
    <Router>
      { this.state.lg ? <Header u={this.state.u}/> : <Login iniciar={this.iniciar} urlws={this.state.urlws}/>}
      <div style={estilo}>
        <Switch>
          <Route exact path="/empresa" render={(props) => <Empresa {...props} u={this.state.u} />}/>
          {this.state.u.Empresa === 1 ?
            <Route exact path="/empresas" render={(props) => <Empresas {...props} u={this.state.u} />}/>
          :
            null
          }
          <Route exact path="/usuarios" render={(props) => <Usuarios {...props} u={this.state.u} />}/>
          <Route exact path="/productos" render={(props) => <Productos {...props} u={this.state.u} />}/>
          <Route exact path="/clientes" render={(props) => <Clientes {...props} u={this.state.u} />}/>
          <Route exact path="/cfdi" render={(props) => <CFDi {...props} u={this.state.u} urlws={this.state.urlws}/>}/>
        </Switch>
      </div>
    </Router>
  );
  }
}

export default App;
