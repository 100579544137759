import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Col from 'react-bootstrap/Col';

import Buscador from './Buscador';

class Captura extends React.Component {
    estadoInicial = {
        show:false,
        titulo:'',
        renActual:0,
        editando:false,
        pedirDescrip:false,
        codigo: '',
        nombre: '',
        precio: 0,
        importe: 0,
        cantidad: 1,
        formaPago:'',
        metodoPago:'',
        usoCfdi:'',
        cliente: 0,
        esGlobal:false,
        fechaIni: new Date().yyyymmdd('-'),
        fechaFin: new Date().yyyymmdd('-'),
        periodicidad:'01',
        tipoRel:'',
        folioRel:'',
        ivaIncluido:false,
        detalle: []
    };
    constructor(props) {
        super(props);
        this.state = this.estadoInicial;
    }
    componentDidMount(){
        const estado = localStorage.getItem("captura");
        if(estado === null)
            return;
        this.setState(JSON.parse(estado));
    }
    componentWillUnmount(){
        const estado = JSON.stringify(this.state);
        localStorage.setItem("captura", estado);
    }
    cargarArticulo = async (cod) => {
        const url = `${this.props.urlws}/articulo/wscargar/${cod}?emp=${this.props.emp}`;
        const res = await fetch(url);
        const arti = await res.json();
        if (arti.Empresa === 0) {
            return;
        }
        this.setState({ codigo: cod, nombre: arti.Nombre, ivaIncluido: arti.IvaIncluido, pedirDescrip:arti.PedirDescrip, precio: arti.Precio, importe: arti.Precio * this.state.cantidad });
    }
    codigoBuscador = (codigo) => {
        this.cargarArticulo(codigo);
    }
    handleClose = () => {
        this.setState({ show: false });
    }
    nuevo = () => {
        this.setState({ show: true, editando : false, titulo:'agregar concepto' });
    }
    onChangeCh = (e) => {
        const campo = e.target.name;
        let valor = e.target.checked;
        this.setState({ [campo]: valor});
    }
    onChange = (e) => {
        const campo = e.target.name;
        let imp = this.state.importe;
        let valor = e.target.value;
        let cargarArt = false;
        let actFormas = false;

        if (campo === "cantidad")
            imp = this.state.precio * Number(valor);
        if (campo === "precio")
            imp = this.state.cantidad * Number(valor);
        if (campo === "codigo"){
            cargarArt = valor !== this.state.codigo;
        }            
        if (campo === "cliente"){
            // actualizar forma,metodo,uso;
            for(let k = 0; k < this.props.clientes.length; k++)
               if(this.props.clientes[k].Id === valor){
                   actFormas = true;
                   const formaPago = this.props.clientes[k].Dato2;
                   const metodoPago = this.props.clientes[k].Dato3;
                   const usoCfdi = this.props.clientes[k].Dato4;
                   this.setState({ [campo]: valor, formaPago, metodoPago, usoCfdi });
                }
        }          
        if(!actFormas){
            this.setState({ [campo]: valor, importe: imp },()=>{
                if(cargarArt)
                    this.cargarArticulo(valor);
            });
        }
    }
    agregar = () => {
        const item = {
            show:false,
            codigo: this.state.codigo,
            nombre: this.state.nombre,
            cantidad: this.state.cantidad,
            precio: this.state.precio,
            importe: this.state.importe,
            pedirDescrip : this.state.pedirDescrip
        }
        let det = this.state.detalle;
        if(this.state.editando){
            det[this.state.renActual] = item;
        }
        else
            det.push(item);
        this.setState({ detalle: det, editando : false });
    }
    editar = (i) =>{
        const ren = this.state.detalle[i];
        this.setState({
            renActual:i,
            editando:true,
            pedirDescrip:ren.pedirDescrip,
            codigo: ren.codigo,
            nombre: ren.nombre,
            precio: ren.precio,
            importe: ren.importe,
            cantidad: ren.cantidad,
            show:true,
            titulo:'editando concepto'
        })
    }
    eliminar = (i) => {
        let det = this.state.detalle;
        det.splice(i, 1);
        this.setState({ detalle: det });
    }
    guardar = () =>{
        console.log(this.state);
        if(this.state.tipoRel !== '' && this.state.folioRel === ''){
            Swal.fire('Atencion','Debe indicar el folio numerico del documento relacionado','success');
            return;
        }
        if(this.state.formaPago === '' || this.state.metodoPago === '' || this.state.usoCfdi === '' || this.state.cliente === 0){
            Swal.fire('Atencion','Falta de especificar algunos datos','success');
            return;
        }

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value)
                this.guardarB();
        });
    }
    guardarB = async() =>{
        let det = [];
        const rows = this.state.detalle;
        for(let i = 0; i < rows.length; i++) {
            det.push({
                cod: rows[i].codigo,
                can: rows[i].cantidad,
                pre: rows[i].precio,
                des: rows[i].nombre
            });
        }
        let json = JSON.stringify(det);
        let data = {
            empresa : this.props.emp,
            metodo: this.state.metodoPago,
            forma: this.state.formaPago,
            uso: this.state.usoCfdi,
            cte: this.state.cliente,
            tipoRel: this.state.tipoRel,
            folioRel: this.state.folioRel,
            ivaIncluido: this.state.ivaIncluido?'S':'N',
            global:this.state.esGlobal?'S':'N',
            fechaIni:this.state.fechaIni,
            fechaFin:this.state.fechaFin,
            periodicidad:this.state.periodicidad,
            s: json
        }
        const res = await this.props.guardar(data);
        if(res === "ok"){
            this.setState(this.estadoInicial);
            this.state.detalle = [];
        }
    }
    cancelar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea salir sin guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.setState(this.estadoInicial);
                this.setState({detalle:[]});
                this.props.cancelar();
            }
        });
    }
    urlBusq = `https://www.facturar.isaerpweb.com/articulo/wslista?emp=${this.props.emp}&filtro=`;
    popCod = (
        <Popover id="popover-basic" title="Buscar concepto" container={this.props.parent}>
            <Buscador onSelect={this.codigoBuscador} url={this.urlBusq} />
        </Popover>
    );
    render() {
        let suma = 0;
        for(let k = 0; k < this.state.detalle.length; k++)
           suma = suma + this.state.detalle[k].importe;    
        return (
            <React.Fragment>
                <div className="row">
                    <Form.Group as={Col} md="4">
                        <Form.Label>Cliente</Form.Label>
                        <Form.Control as="select" name="cliente" value={this.state.cliente} onChange={this.onChange}>
                            <option key="0" value="0">Seleccione</option>
                            {this.props.clientes.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="1">
                        <Form.Label>.</Form.Label><br/>
                        <Button variant='outline-success' size="sm" onClick={this.props.cargarClientes}><img src="refresh.png" alt="actualizar" /></Button>
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                        <Form.Label>Forma de pago</Form.Label>
                        <Form.Control as="select" name="formaPago" value={this.state.formaPago} onChange={this.onChange}>
                            <option key="0" value="">Seleccione</option>
                            {this.props.formas.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                        <Form.Label>Metodo de pago</Form.Label>
                        <Form.Control as="select" name="metodoPago" value={this.state.metodoPago} onChange={this.onChange}>
                            <option key="0" value="">Seleccione</option>
                            {this.props.metodos.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                        <Form.Label>Uso del CFDi</Form.Label>
                        <Form.Control as="select" name="usoCfdi" value={this.state.usoCfdi} onChange={this.onChange}>
                            <option key="0" value="">Seleccione</option>
                            {this.props.usos.map((i, k) =>
                                <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Tipo de relacion</Form.Label>
                                <Form.Control as="select" name="tipoRel" value={this.state.tipoRel} onChange={this.onChange}>
                                    <option key="0" value="">Seleccionar</option>
                                    {this.props.relaciones.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            {this.state.tipoRel != '' &&
                            <div className="col-2">
                                <Form.Label>Folio relacionado</Form.Label>
                                <input size="sm" type="text" className="form-control" name="folioRel" value={this.state.folioRel} onChange={this.onChange} />
                            </div>
                            } 
                </div>
                <div className="row">
                    <div className='col-2'>
                        <Form.Check type="checkbox" label="Factura global" checked={this.state.esGlobal} name="esGlobal" onChange={this.onChangeCh} />
                    </div>
                    {this.state.esGlobal && <div className='col'>
                        <table>
                            <tr>
                                <td>Del dia</td>
                                <td><Form.Control size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} /></td>
                                <td>al dia</td>
                                <td><Form.Control size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} /></td>
                                <td>Periodicidad</td>
                                <td><Form.Control size="sm" as="select" name="periodicidad" value={this.state.periodicidad} onChange={this.onChange}>
                                    <option key="0" value="0">Todos</option>
                                    {this.props.periodicidad.map((i, k) =>
                                        <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                    )}
                                    </Form.Control>
                                </td>
                            </tr>
                        </table>
                    </div>
                    }
                </div>
                <div className="row" style={{ height: 350, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100, textAlign: 'right' }}>Cantidad</th>
                                <th style={{ width: 75 }}>Codigo</th>
                                <th>Nombre</th>
                                <th style={{ width: 100, textAlign: 'right' }}>Precio</th>
                                <th style={{ width: 100, textAlign: 'right' }}>Importe</th>
                                <th style={{ width: 85 }}></th>
                                <th style={{ width: 85 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.detalle.map((e, i) => (
                                <tr>
                                    <td>{e.cantidad}</td>
                                    <td>{e.codigo}</td>
                                    <td>{e.nombre}</td>
                                    <td style={{ width: 100, textAlign: 'right' }}>{e.precio}</td>
                                    <td style={{ width: 100, textAlign: 'right' }}>{e.importe}</td>
                                    <td><Button variant="success" size="sm" onClick={() => this.editar(i)}>Editar</Button></td>
                                    <td><Button variant="danger" size="sm" onClick={() => this.eliminar(i)}>Eliminar</Button></td>
                                </tr>
                            ))}
                                <tr>
                                    <td></td>
                                    <td>SUBTOTAL</td>
                                    <td></td>
                                    <td style={{ width: 100, textAlign: 'right' }}></td>
                                    <td style={{ width: 100, textAlign: 'right' }}>{suma}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="row">
                    <div className="col-3">
                        <Button variant="success" onClick={this.nuevo}>+</Button> {'  '}
                        <Button variant="success" onClick={this.guardar}>Guardar</Button> {'  '}
                        <Button variant="danger" onClick={this.cancelar}>Cancelar</Button> {'  '}
                    </div>
                </div>

                <Modal id="dlg" name="dlg" show={this.state.show} onHide={this.handleClose} size="lg" tabIndex={1}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <Form.Group as={Col}>
                                <Form.Label>Concepto</Form.Label>
                                <Form.Control as="select" name="codigo" value={this.state.codigo} onChange={this.onChange}>
                                    <option key="0" value="">Seleccione</option>
                                {this.props.codigos.map((i, k) =>
                                    <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                                )}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group" size="sm">
                                    <label>Cantidad</label>
                                    <Form.Control size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group" size="sm">
                                    <label>Precio</label>
                                    <Form.Control size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group" size="sm">
                                    <label>Importe</label>
                                    <Form.Control size="sm" type="text" name="importe" value={this.state.importe} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group" size="sm">
                                    <label>Descripcion</label>
                                    <textarea class="form-control" rows="5" name="nombre" value={this.state.nombre} onChange={this.onChange} readOnly={this.state.pedirDescrip} ></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.agregar}>Agregar</Button>
                        <Button variant="danger" onClick={this.handleClose}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>                
            </React.Fragment>
        )
    }
}
export default Captura;