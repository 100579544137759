import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'

export default class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            empresa:'demo', // 'MARISOL', // 'demo',
            email:'demo@demo.com', // 'emisioncfdi@gmail.com', // 'demo@demo.com',
            pwd:'123456' // 'Admin2018' //'123456'
        };
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onSubmit = (e) =>{
        e.preventDefault();
        this.loguear();
    }
    loguear  = async(data) => { //async() => {
        const email = this.state.email;
        const pwd = this.state.pwd;
        const emp = this.state.empresa;
        const url = `${this.props.urlws}/home/wsloguear?emp=${emp}&email=${email}&pwd=${pwd}`;
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id === 0){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        this.props.iniciar(res);
    }

    render(){
        return(
            <div className="container">
                <br/><br/><br/><br/><br/>
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Sistema de facturacion electronica</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Empresa</label>
                                        <Form.Control size="sm" type="text" name="empresa" value={this.state.empresa} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Email</label>
                                        <Form.Control size="sm" type="text" name="email" value={this.state.email} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group" size="sm">
                                        <label>Contraseña</label>
                                        <Form.Control size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" className="btn btn-success" value="Iniciar sesion" />
                        </form>
                    </Modal.Body>

                    <Modal.Footer>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        )
    }
}