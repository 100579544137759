import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


function ClientesForm(props) {
    const [cliente, guardarCliente] = useState(props.cliente);

    function onChange(e) {
        const estado = {
            ...cliente,
            [e.target.name]: e.target.value
        };
        guardarCliente(estado);
    }
    function onSubmit(e) {
        e.preventDefault();
        const MySwal = withReactContent(Swal)


        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                props.guardar(cliente);
            }
          })        
        //MySwal.fire(<p>Shorthand works too</p>);
    }
    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Nombre</label>
                        <Form.Control size="sm" type="text" name="Nombre" value={cliente.Nombre} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>R.F.C.</label>
                        <Form.Control size="sm" type="text" name="Rfc" value={cliente.Rfc} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="form-group" size="sm">
                        <label>Calle</label>
                        <Form.Control size="sm" type="text" name="Calle" value={cliente.Calle} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Num.Exterior</label>
                        <Form.Control size="sm" type="text" name="NumeroExt" value={cliente.NumeroExt} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Num.Interior</label>
                        <Form.Control size="sm" type="text" name="NumeroInt" value={cliente.NumeroIn} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Colonia</label>
                        <Form.Control size="sm" type="text" name="Colonia" value={cliente.Colonia} onChange={onChange} />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>C.P.</label>
                        <Form.Control size="sm" type="text" name="CP" value={cliente.CP} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Ciudad</label>
                        <Form.Control size="sm" type="text" name="Ciudad" value={cliente.Ciudad} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Municipio</label>
                        <Form.Control size="sm" type="text" name="Municipio" value={cliente.Municipio} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Estado</label>
                        <Form.Control size="sm" type="text" name="Estado" value={cliente.Estado} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Pais</label>
                        <Form.Control size="sm" type="text" name="Pais" value={cliente.Pais} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Telefono</label>
                        <Form.Control size="sm" type="text" name="Telefono" value={cliente.Telefono} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Email</label>
                        <Form.Control size="sm" type="text" name="Email" value={cliente.Email} onChange={onChange} />
                    </div>
                </div>
                <div className="col-1">
                    <div className="form-group" size="sm">
                        <label>%Ret.IVA</label>
                        <Form.Control size="sm" type="text" name="PtjeRetIva" value={cliente.PtjeRetIva} onChange={onChange} />
                    </div>
                </div>
                <div className="col-1">
                    <div className="form-group" size="sm">
                        <label>%Ret.ISR</label>
                        <Form.Control size="sm" type="text" name="PtjeRetIsr" value={cliente.PtjeRetIsr} onChange={onChange} />
                    </div>
                </div>
                <Form.Group as={Col} size="sm">
                    <Form.Label>Regimen</Form.Label>
                    <Form.Control as="select" size="sm" name="Regimen" value={cliente.Regimen} onChange={onChange}>
                        <option value="">Especifique</option>
                        {props.regimenes.map((i,k) =>
                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                        )}
                    </Form.Control>
                </Form.Group>
            </div>
            <div className="row">
                <Form.Group as={Col} md="4">
                    <Form.Label>Forma de pago</Form.Label>
                    <Form.Control as="select" name="FormaPago" value={cliente.FormaPago} onChange={onChange}>
                        <option value="">Especifique</option>
                        {props.formas.map((i,k) =>
                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Metodo de pago</Form.Label>
                    <Form.Control as="select" name="MetodoPago" value={cliente.MetodoPago} onChange={onChange}>
                        <option value="">Especifique</option>
                        {props.metodos.map((i,k) =>
                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                        )}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Uso del CFDi</Form.Label>
                    <Form.Control as="select" name="UsoCfdi" value={cliente.UsoCfdi} onChange={onChange}>
                        <option value="">Especifique</option>
                        {props.usos.map((i,k) =>
                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                        )}
                     </Form.Control>
                </Form.Group>
            </div>
            <input type="submit" className="btn btn-sm btn-outline-success" value="Guardar" />
            {' '}
            <button className="btn btn-sm btn-outline-danger" onClick={props.cancelar}>Cancelar</button>
        </form>
    )
}
export default ClientesForm;