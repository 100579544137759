import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

class Empresa extends React.Component{
    empNueva = {Id:0, Nombre:'',Calle:'',Orientacion:'',NumeroExt:'',NumeroInt:'',Colonia:'',Rfc:'',Ciudad:'',Municipio:'',Estado:'',Pais:'',Telefono:'',Celular:'',CP:'',Email:'',Regimen:'', Adicional1:'',Adicional2:'',Adicional3:''};
    constructor(props){
        super(props);
        this.state = {
            ...this.empNueva,
            emp : this.props.u.Empresa,
            regimenes:[]
        }
    }
    componentDidMount = () =>{
        const estado = localStorage.getItem("u");
        this.cargarRegimenes();
        this.cargaEmpresa();
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onChangeCh = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        });
    }
    onSubmit = (e) =>{
        e.preventDefault();
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardar(this.state);
            }
          })        
        //MySwal.fire(<p>Shorthand works too</p>);
    }
    
    cargarRegimenes = async () => {
        const url = "https://facturar.isaerpweb.com/home/wslistaRegimenes";
        const res = await fetch(url);
        const regimenes = await res.json();
        this.setState({ regimenes });
    }
    cargaEmpresa = async () => {
        const url = "https://facturar.isaerpweb.com/home/wscargaEmpresa/" + this.state.emp;
        const res = await fetch(url);
        const empresa = await res.json();
        this.setState({ ...empresa });
    }
    guardar = async(empresa) => {
        const url = "https://www.facturar.isaerpweb.com/home/wsguardarEmpresa";
        const req = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(empresa), // data can be `string` or {object}!
          })
        const res = await req.text();
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        this.cargaEmpresa();
        Swal.fire('Atencion','Datos guardados.','success');
    }

    render(){
        return(
            <div className="container">
            <form onSubmit={this.onSubmit}>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Nombre</label>
                        <Form.Control size="sm" type="text" name="Nombre" value={this.state.Nombre} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>R.F.C.</label>
                        <Form.Control size="sm" type="text" name="Rfc" value={this.state.Rfc} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="form-group" size="sm">
                        <label>Calle</label>
                        <Form.Control size="sm" type="text" name="Calle" value={this.state.Calle} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Num.Exterior</label>
                        <Form.Control size="sm" type="text" name="NumeroExt" value={this.state.NumeroExt} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group" size="sm">
                        <label>Num.Interior</label>
                        <Form.Control size="sm" type="text" name="NumeroInt" value={this.state.NumeroIn} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Colonia</label>
                        <Form.Control size="sm" type="text" name="Colonia" value={this.state.Colonia} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>C.P.</label>
                        <Form.Control size="sm" type="text" name="CP" value={this.state.CP} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Ciudad</label>
                        <Form.Control size="sm" type="text" name="Ciudad" value={this.state.Ciudad} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Municipio</label>
                        <Form.Control size="sm" type="text" name="Municipio" value={this.state.Municipio} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Estado</label>
                        <Form.Control size="sm" type="text" name="Estado" value={this.state.Estado} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Pais</label>
                        <Form.Control size="sm" type="text" name="Pais" value={this.state.Pais} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="form-group" size="sm">
                        <label>Telefono</label>
                        <Form.Control size="sm" type="text" name="Telefono" value={this.state.Telefono} onChange={this.onChange} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group" size="sm">
                        <label>Email</label>
                        <Form.Control size="sm" type="text" name="Email" value={this.state.Email} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <Form.Group as={Col} md="8">
                    <Form.Label>Regimen</Form.Label>
                    <Form.Control as="select" name="Regimen" value={this.state.Regimen} onChange={this.onChange}>
                        {this.state.regimenes.map((i,k) =>
                            <option key={i.Id} value={i.Id}>{i.Dato1}</option>
                        )}
                    </Form.Control>
                </Form.Group>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Dato adicional 1</label>
                        <Form.Control size="sm" type="text" name="Adicional1" value={this.state.Adicional1} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Dato adicional 2</label>
                        <Form.Control size="sm" type="text" name="Adicional2" value={this.state.Adicional2} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group" size="sm">
                        <label>Dato adicional 1</label>
                        <Form.Control size="sm" type="text" name="Adicional3" value={this.state.Adicional3} onChange={this.onChange} />
                    </div>
                </div>
            </div>
            <input type="submit" className="btn btn-success" value="Guardar" />
        </form>
        </div>
        )
    }
}

export default Empresa;